
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getBranchschools } from "../../utils/common";

const qs = require("qs");

import { Caxios } from "./../../utils/axios";

@Component({
  name: "Course",
  computed: {
    ...mapGetters("course", [
      "yearList",
      "grade",
      "subject",
      "termtype",
      "loadMoreFlag",
    ]),
    ...mapGetters("common", ["branchSchoolId", "lang", "login"]),
  },
  methods: {
    ...mapMutations("course", ["setSelectObj", "setLoadMoreFlag"]),
    ...mapActions("course", ["setAjaxData", "setYearList"]),
  },
})
export default class Course extends Vue {
  yearList!: Array<string>;
  grade!: Array<any>;
  subject!: Array<any>;
  termtype!: Array<any>;
  branchSchoolId!: number;
  setSelectObj!: any;
  setAjaxData!: any;
  setYearList!: any;
  loadMoreFlag!: boolean;
  setLoadMoreFlag!: any;
  lang!: any;
  login!: any;
  public personInfo: any =
    localStorage.getItem("singapore_personal_Data") || "";
  private activeFlaglagArr: Array<number> = [0, 0, 0, 0];
  private queryObj: any = {
    limit: 100,
    page: 1,
    keywords: "",
    branch_school_id: 0,
    grade: "",
    subject: "",
    termtype: "",
    type: "No limit",
    course: "No limit",
    year: new Date().getFullYear(),
  };
  private queryObjStr: any = {
    year: new Date().getFullYear(),
    type: "No limit",
    course: "No limit",
    grade: "",
    subject: "",
    termtype: "",
  };

  private curGrade: Array<any> = [];
  private curSubject: Array<any> = [];

  private courseList: Array<any> = [];
  private optionsInfo: any = {};
  private loadingFlag = true;
  private cacheGrade: any = [];

  private allTotalPage = 0;

  @Watch("lang", { immediate: true, deep: true })
  langWatch(newVal: any): void {}
  @Watch("login", { immediate: true, deep: true })
  loginWatch(newVal: any): void {
    console.log("登录状态变化", newVal);
    if (!this.personInfo) {
      this.personInfo = localStorage.getItem("singapore_personal_Data") || "";
    }
    if (typeof this.personInfo === "string") {
      try {
        this.personInfo = JSON.parse(this.personInfo);
      } catch (e) {}
    }
    if (newVal) {
      this.initQueryObj();
    }
  }
  /* gradeWatch(newVal: any): void {
            if(newVal && Array.isArray(newVal) && newVal.length > 0) {
                this.initFilterGrade()
            }
        }
        @Watch('subject', { immediate: true, deep: true })
        subjectWatch(newVal: any): void {
            if(newVal && Array.isArray(newVal) && newVal.length > 0) {
                this.initFilterSubject()
            }
        }
*/
  /* @Watch('loadMoreFlag', { immediate: true, deep: true })
        loadMoreFlagWatch(newVal: boolean): void {
            if(newVal && this.queryObj.page < this.allTotalPage) {
                this.queryObj.page += 1
                this.getCourse(1)
            }
        }*/
  private async onSchoolClick(e: any) {
    console.log("e", e.key, this.optionsInfo.branchSchools);
    /*this.$set(this.queryObj, 'school', this.optionsInfo.branchSchools[e.key].code)
            this.$set(this.queryObjStr, 'school', this.optionsInfo.branchSchools[e.key].name)*/
    this.changeSelect(e.key, this.optionsInfo.branchSchools[e.key], 0);
  }
  // 搜索
  private async searchList() {
    this.getCourse();
    this.formatLocalstorage();
  }
  private async initQueryObj() {
    const _result: any = await Caxios.post({
      url: "/api/official/course/optionsInfo",
    });
    console.log("_result", _result);
    if (_result.code === "10000") {
      // this.optionsInfo = _result.data
      Object.keys(_result.data).forEach((attr: string): void => {
        console.log("attr", attr);
        if (attr === "grades") {
          _result.data[attr].unshift({
            name: this.lang === "zh-CN" ? "不限" : "No limit",
            code: "",
          });
          this.cacheGrade = _result.data[attr];
        } else if (attr === "subjects") {
          _result.data[attr].unshift({
            name: this.lang === "zh-CN" ? "不限" : "All subjects",
            code: "",
          });
        }
      });
      this.$set(this, "optionsInfo", {
        type: [
          {
            name: "No limit",
            code: "No limit",
          },
          {
            name: "GEP",
            code: "GEP",
          },
          {
            name: "Primary Olympiad",
            code: "Primary Olympiad",
          },
          {
            name: "PSLE",
            code: "PSLE",
          },
          {
            name: "Secondary",
            code: "Secondary",
          },
        ],
        course: [
          {
            name: "No limit",
            disabled: false,
            code: "No limit",
          },
          {
            name: "Paid courses",
            disabled: false,
            code: "paid",
          },
          {
            name: "Free courses",
            disabled: false,
            code: "free",
          },
        ],
        ..._result.data,
      });
      this.setQuery();
      console.log("this.queryObj", this.queryObj);
      this.loadingFlag = true;
      this.getCourse();
      /*this.curGrade.map((itm: any): void => {
              if(itm.text == '全部年级') {
                itm.text = this.lang === 'zh-CN' ? '不限' : 'All grades'
              }
              if(itm.text == '不限' || itm.text === 'All grades') {
                itm.text = this.lang === 'zh-CN' ? '不限' : 'All grades'
              }
            })*/
    }
  }
  private setQuery() {
    let localDataStr: any = localStorage.getItem(
      "fangtian_course_query_obj_str"
    );
    let localData: any = localStorage.getItem("fangtian_course_query");
    if (localData) {
      if (typeof localDataStr === "string") {
        try {
          localDataStr = JSON.parse(localDataStr);
        } catch (e) {}
      }
      if (typeof localData === "string") {
        try {
          localData = JSON.parse(localData);
        } catch (e) {}
      }
      if (
        Object.keys(localDataStr).length > 0 &&
        Object.keys(localData).length > 0
      ) {
        this.queryObj.school = localData.school;
        this.queryObjStr.school = localDataStr.school;
        this.queryObj.year = localData.year;
        this.queryObjStr.year = localDataStr.year;
        this.queryObj.grade = localData.grade;
        this.queryObjStr.grade = localDataStr.grade;
        this.queryObj.subject = localData.subject;
        this.queryObjStr.subject = localDataStr.subject;
      }
      console.log("localDataStr", localDataStr);
      console.log("localData", localData);
    } else {
      if (this.personInfo) {
        const find = this.optionsInfo.branchSchools.find(
          (cell: any) => cell.code === this.personInfo.branchSchoolCode
        );
        if (find) {
          this.queryObj.school = find.code;
          this.queryObjStr.school = find.name;
        } else {
          this.queryObj.school = this.optionsInfo.branchSchools[0]?.code;
          this.queryObjStr.school = this.optionsInfo.branchSchools[0]?.name;
        }
      } else {
        this.queryObj.school = this.optionsInfo.branchSchools[0]?.code;
        this.queryObjStr.school = this.optionsInfo.branchSchools[0]?.name;
      }
    }
  }
  private async getCourse() {
    const _result: any = await Caxios.post({
      url: "/api/official/course/courseCards",
      data: {
        branchSchoolCode: this.queryObj.school,
        keywords: this.queryObj.keywords,
        gradeCodeList: [this.queryObj.grade],
        classification: this.queryObj.course,
      },
    });
    this.loadingFlag = false;
    if (_result.code === "10000") {
      this.$set(this, "courseList", _result.data);
      if (!this.clickCourse) {
        let courseSelect: any[] = []; 
        if (_result.data.every((item: any) => item.totalPrice > 0)) {
          if (_result.data.length === 0) {
            courseSelect = this.optionsInfo.course.map((item: any) => {
              item.disabled = true;
              return item;
            });
          } else {
            
            courseSelect = this.optionsInfo.course.map((item: any) => {
              if (item.code === "free") {
                item.disabled = true;
              } else {
                item.disabled = false;
              }
              return item;
            });
          }
        } else {
          courseSelect = this.optionsInfo.course.map((item: any) => {
            item.disabled = false;
            return item;
          });
        }
        this.$set(this.optionsInfo, "course", courseSelect);
      }
    }
  }
  /* private restQuery (): void {
            const _arr: Array<string> = ['year', 'grade', 'subject', 'termtype']
            _arr.forEach((attr: string): void => {
                this.queryObj[attr] = ''
                this.queryObjStr[attr] = ''
            })

            localStorage.removeItem('fangtian_course_query')
            localStorage.removeItem('fangtian_course_query_obj_str')
        }
        private async getCourse (type: number) {
            const data: any = { ...this.queryObj },
                _this: any = this;
            console.log('data---------', data)
            Object.keys(data).forEach((attr: string): void => {
                if(data[attr] == '不限' || data[attr] == "No limit") {
                    delete data[attr]
                }
            })
            this.loadingFlag = true
            const result: any = await Caxios.get({ url: `/api/rightcourses?${qs.stringify(data)}` })
            if(result && result.records && Array.isArray(result.records) && result.records.length > 0) {
                if(type == 0) {
                    this.courseList = result.records
                } else {
                    result.records.forEach((item: any): void => {
                        _this.$set(this.courseList, this.courseList.length, item)
                    })
                }


                let _temp: Array<string> = this.lang === 'zh-CN' ? ['不限'] : ['No limit'],
                    _unSort: Array<string> = [];
                this.courseList.forEach((item: any): void => {
                    let _str = ''
                    if(item.start_time) {
                        _str = item.start_time.substr(0, 4)
                        _unSort.push(_str)
                    }
                })
                _unSort = Array.from(new Set(_unSort)).sort((a: string, b: string): any => { return Number(a) - Number(b) })
                _temp = [ ..._temp, ..._unSort ]
                // this.setYearList(_temp)

                this.setLoadMoreFlag(false)
            } else {
                this.courseList = []
            }
            this.allTotalPage = result.pages
            this.loadingFlag = false
        }
        // 获取年份
        private async getYears () {
            const branchschoolid: string = this.queryObj.branch_school_id
            console.log(branchschoolid, '././././././')
            const result: any = await Caxios.get({url: `/api/datadict/YEAR?branch_school_id=${branchschoolid}&code=YEAR`})
            if (result?.length > 0) {
                const years = result.map((item: any): string => item.text)
                this.setYearList(years)
            }
        }
        private formatLocalstorage (): void {
            localStorage.setItem('fangtian_course_query', JSON.stringify(this.queryObj))
            localStorage.setItem('fangtian_active_flaglag_arr', this.activeFlaglagArr.join(','))
            localStorage.setItem('fangtian_course_query_obj_str', JSON.stringify(this.queryObjStr))
        }*/
  private formatLocalstorage(): void {
    localStorage.setItem(
      "fangtian_course_query",
      JSON.stringify(this.queryObj)
    );
    localStorage.setItem(
      "fangtian_active_flaglag_arr",
      this.activeFlaglagArr.join(",")
    );
    localStorage.setItem(
      "fangtian_course_query_obj_str",
      JSON.stringify(this.queryObjStr)
    );
  }
  private clickCourse = false;
  private changeSelect(idx: number, itm: any, type: number) {
    this.$set(this.activeFlaglagArr, type, idx);
    this.clickCourse = false;
    switch (type) {
      case 0:
        console.log(itm);
        this.queryObj.school = itm.code;
        this.queryObjStr.school = itm.name;
        
        break;
      case 1:
        this.queryObj.year = itm.code;
        this.queryObjStr.year = itm.name;
        break;
      case 2: // 年级
      if (itm.disabled) {
          return false;
        } else {
          this.queryObj.grade = itm.code;
        this.queryObjStr.grade = itm.name;
        this.queryObj.course = this.optionsInfo.course[0].code;
          this.queryObjStr.course = this.optionsInfo.course[0].name;
        }
        break;
      case 3: // 学科
        this.queryObj.subject = itm.code;
        this.queryObjStr.subject = itm.name;
        break;
      case 4:
        this.queryObj.termtype = itm.code;
        this.queryObjStr.termtype = itm.name;
      case 5:
        this.queryObj.type = itm.code;
        this.queryObjStr.type = itm.name;
        console.log("this.cacheGrade", this.cacheGrade);
        let newArr: any = [];
        if (itm.code === "No limit") {
          this.optionsInfo.grades = this.cacheGrade.map(
            (cell: any) => {
                cell.disabled = false
                return cell
            }
          )
        } else {
          if (itm.code === "GEP") {
            newArr = this.cacheGrade.map((cell: any) => {
              if (cell.code === "No limit") {
                cell.disabled = false;
              } else {
                if (
                  cell.code === "1-1" ||
                  cell.code === "1-2" ||
                  cell.code === "1-3" ||
                  cell.code === ''
                ) {
                  cell.disabled = false;
                } else {
                  cell.disabled = true;
                }
              }
              return cell;
            })
          } else if (itm.code === "Primary Olympiad") {
            newArr = this.cacheGrade.map((cell: any) => {
              if (cell.code === "No limit") {
                cell.disabled = false;
              } else {
                if (
                  cell.code === "1-4" ||
                  cell.code === "1-5" ||
                  cell.code === "1-6" ||
                  cell.code === ''
                ) {
                  cell.disabled = false;
                } else {
                  cell.disabled = true;
                }
              }
              return cell;
            })
          } else if (itm.code === "PSLE") {
            newArr = this.cacheGrade.map((cell: any) => {
              if (cell.code === "No limit") {
                cell.disabled = false;
              } else {
                if (cell.code === "1-5" || cell.code === "1-6" || cell.code === "") {
                  cell.disabled = false;
                } else {
                  cell.disabled = true;
                }
              }
              return cell
            })
          } else if (itm.code === "Secondary") {
            newArr = this.cacheGrade.map((cell: any) => {
              if (cell.code === "No limit") {
                cell.disabled = false;
              } else {
                if (
                  cell.code === "2-1" ||
                  cell.code === "2-2" ||
                  cell.code === "2-3" ||
                  cell.code === "2-4" ||
                  cell.code === "2-5" ||
                  cell.code === "2-6" || 
                  cell.code === "3-1" ||
                   cell.code === "3-2" || 
                  cell.code === ""
                ) {
                  cell.disabled = false;
                } else {
                  cell.disabled = true;
                }
              }
              return cell;
            });
          }
          this.queryObj.grade = newArr[0].code;
          this.queryObjStr.grade = newArr[0].name;
          this.optionsInfo.grades = newArr;
          this.queryObj.course = this.optionsInfo.course[0].code;
          this.queryObjStr.course = this.optionsInfo.course[0].name;
        }
        break;
      case 6:
        if (itm.disabled) {
          return false;
        } else {
          this.queryObj.course = itm.code;
          this.queryObjStr.course = itm.name;
          this.clickCourse = true;
        }
        break;
    }

    this.getCourse();
    this.formatLocalstorage();
    /* this.formatLocalstorage()
            this.queryObj.page = 1
            this.getCourse(0)*/
  }
  private delQueryOption(attr: string, type: number): void {
    this.$set(this.activeFlaglagArr, type, 0);
    if(attr === 'type'){
        this.$set(this.queryObj, attr, "No limit");
        this.optionsInfo.grades = this.cacheGrade.map((item: any)=>{
            item.disabled = false
            return item
          });
    }else{
this.$set(this.queryObj, attr, "");
    }
    this.$set(this.queryObjStr, attr, "");
    this.getCourse();
    this.queryObj.page = 1;
    this.formatLocalstorage();
    /*if(attr == 'grade') {
          this.initFilterSubject()
        }
        if(attr == 'subject') {
          this.initFilterGrade()
        }
        this.formatLocalstorage()
        this.getCourse(0)*/
  }
  private delAllEvent() {
    this.activeFlaglagArr.forEach((v: number, i: number): void => {
      this.$set(this.activeFlaglagArr, i, -1);
    });
    const _arr = [
      "school",
      "year",
      "grade",
      "subject",
      "termtype",
      "type",
      "course",
    ];
    Object.keys(this.queryObjStr).forEach((attr: string): void => {
      if (_arr.includes(attr)) {
        if (attr === "type") {
          this.optionsInfo.grades = this.cacheGrade.map((item: any)=>{
            item.disabled = false
            return item
          });
        }
         this.$set(this.queryObjStr, attr, "");

      }
    });
    Object.keys(this.queryObj).forEach((attr: string): void => {
      if (_arr.includes(attr)) {
        if(attr === "school" || attr === "grade"){
            this.$set(this.queryObj, attr, "");
        }else{
            this.$set(this.queryObj, attr, "No limit");
        }
      }
    });
    this.queryObj.page = 1;
    this.clickCourse = false
    this.getCourse();
  }
  private changeRouteToTestNear(type: number): void {
    sessionStorage.removeItem("fangtian_person_to_course_orderid");
    const _this: any = this;
    if (type == 1) {
      _this.$router.push(
        `/course/detail?courseid=0&branchSchoolCode=${this.queryObj.school}`
      );
    } else {
      _this.$router.push(`/nearbyCampus`);
    }
  }

  private gotoDetailEvent(item: any): void {
    sessionStorage.removeItem("fangtian_person_to_course_orderid");
    const _this: any = this;
    _this.$router.push({
      path: "/course/detail",
      query: {
        courseid: item.courseId,
        grade: item.gradeCode,
        subject: item.subjectCode,
        branchSchoolCode: this.queryObj.school,
      },
    });
    localStorage.setItem("courseList", JSON.stringify(this.courseList));
  }
  /* private initFilterGrade (): void {
            this.curGrade = []
            if(this.branchSchoolId == 2) {
                this.curGrade = [ ...this.grade ]
            } else {
                this.curGrade = this.grade.filter((item: any): boolean => { return item.text.indexOf('高') < 0 })
            }
            this.curGrade.map((itm: any): void => {
                if(itm.text == '全部年级') {
                    itm.text = this.lang === 'zh-CN' ? '不限' : 'All grades'
                }
                if(itm.text == '不限' || itm.text === 'All grades') {
                    itm.text = this.lang === 'zh-CN' ? '不限' : 'All grades'
                }
            })
        }
        private initFilterSubject (): void {
            this.curSubject = []
            if(this.branchSchoolId == 2) {
                this.curSubject = [ ...this.subject ]
            } else {
                this.curSubject = this.subject.filter((item: any): boolean => { return item.text != '英语' })
            }
            this.curSubject.map((itm: any): void => {
                if(itm.text == '不限' || itm.text === 'All subjects') {
                    itm.text = this.lang === 'zh-CN' ? '不限' : 'All subjects'
                }
           - })
        }
        // 根据学科筛选年级
        private filterGrade (): void {
            const _this: any = this,
                _arr: Array<any> = [ ...this.grade ];
            this.curGrade = []
            if(this.branchSchoolId == 2) {
                if(this.queryObj.subject == '1' || this.queryObj.subject == '' || this.queryObj.subject == 6) {
                    this.curGrade = _arr
                }
                if(this.queryObj.subject == '3') {
                    this.curGrade = _arr.filter((item: any): boolean => { return item.text.indexOf('年级') < 0 && item.text != '幼大班' })
                }
                if(this.queryObj.subject == '4') {
                    this.curGrade = _arr.filter((item: any): boolean => { return item.text.indexOf('年级') < 0 && item.text != '幼大班' && item.text != '初一' })
                }
            } else {
                // 数 1, 理 3, 化 4, 英 6
                if(this.queryObj.subject == '1' || this.queryObj.subject == '') {
                    this.curGrade = _arr.filter((item: any): boolean => { return item.text.indexOf('高') < 0 })
                }
                if(this.queryObj.subject == '3') {
                    this.curGrade = _arr.filter((item: any): boolean => { return item.id.indexOf('2-') >= 0 || item.text == (this.lang === 'zh-CN' ? '不限' : 'All grades') })
                }
                if(this.queryObj.subject == '4') {
                    this.curGrade = _arr.filter((item: any): boolean => { return (item.id.indexOf('2-') >= 0 && item.text != '初一') || item.text == (this.lang === 'zh-CN' ? '不限' : 'All grades') })
                }
            }
        }
        // 根据年级筛选学科
        private filterSubject (): void {
            const _this: any = this
            this.curSubject = []
            if(this.branchSchoolId == 2) {
                if(this.queryObj.grade.indexOf('1-') >= 0 || this.queryObj.grade.indexOf('4-') >= 0) {
                    _this.subject.forEach((item: any): void => {
                        if(item.text != '化学' && item.text != '物理') {
                            _this.$set(_this.curSubject, _this.curSubject.length, item)
                        }
                    })
                } else {
                    if(this.queryObj.grade.indexOf('2-') >= 0) {
                        _this.subject.forEach((item: any): void => {
                            if(item.text != '化学') {
                                _this.$set(_this.curSubject, _this.curSubject.length, item)
                            }
                        })
                    } else {
                        this.curSubject = [ ...this.subject ]
                    }
                }
            } else {
                if(this.queryObj.grade.indexOf('1-') >= 0 || this.queryObj.grade.indexOf('4-') >= 0) {
                    _this.subject.forEach((item: any): void => {
                        if(item.text == '数学' || item.text == (this.lang === 'zh-CN' ? '不限' : 'All subjects')) {
                            _this.$set(_this.curSubject, _this.curSubject.length, item)
                        }
                    })
                } else if (this.queryObj.grade == '2-1') {
                    _this.subject.forEach((item: any): void => {
                        if(item.text == '数学' || item.text == '物理' || item.text == (this.lang === 'zh-CN' ? '不限' : 'All subjects')) {
                            _this.$set(_this.curSubject, _this.curSubject.length, item)
                        }
                    })
                } else if (this.queryObj.grade == '2-2' || this.queryObj.grade == '2-3') {
                    _this.subject.forEach((item: any): void => {
                        if(item.text != '英语') {
                            _this.$set(_this.curSubject, _this.curSubject.length, item)
                        }
                    })
                } else {
                    this.initFilterSubject()
                }
            }
        }
        private delQueryOption (attr: string, type: number): void {
            this.$set(this.activeFlaglagArr, type, 0)
            this.$set(this.queryObjStr, attr, '')
            this.$set(this.queryObj, attr, '')

            this.queryObj.page = 1

            if(attr == 'grade') {
                this.initFilterSubject()
            }
            if(attr == 'subject') {
                this.initFilterGrade()
            }
            this.formatLocalstorage()
            this.getCourse(0)

        }


        private initQueryObj (): void {
            const fangtian_active_flaglag_arr: any = localStorage.getItem('fangtian_active_flaglag_arr') ? localStorage.getItem('fangtian_active_flaglag_arr') : '0, 0, 0, 0',
                fangtian_curCityPosition: any = localStorage.getItem('fangtian_curCityPosition'),
                fangtian_course_query: any = localStorage.getItem('fangtian_course_query'),
                fangtian_course_query_obj_str: any = localStorage.getItem('fangtian_course_query_obj_str');
            if(fangtian_active_flaglag_arr) {
                const _arr: Array<string> = fangtian_active_flaglag_arr.split(',')
                this.activeFlaglagArr = _arr.map(v => Number(v))
            }
            if(fangtian_course_query) {
                this.queryObj = JSON.parse(fangtian_course_query)
            } else {
                const _cur: any = new Date(),
                    _year: any = _cur.getFullYear();
                this.queryObj.year = _year
                this.yearList.forEach((item: string, index: number): void => {
                    if(item == _year) {
                        this.activeFlaglagArr[0] = index
                    }
                })
            }
            if(fangtian_curCityPosition) {
                this.queryObj.branch_school_id = JSON.parse(fangtian_curCityPosition).id
            }
            if(fangtian_course_query_obj_str) {
                this.queryObjStr = { ...JSON.parse(fangtian_course_query_obj_str) }
            } else {
                this.queryObjStr.year = this.queryObj.year
            }
            this.getCourse(0)
        }

        beforeMount () {
            if (this.personInfo) {
                this.personInfo = JSON.parse(this.personInfo)
            }
        }
        mounted(): void {
            // this.getYears()
            getBranchschools()

        }
        beforeDestroy() {
            this.allTotalPage = 0
            this.queryObj.page = 1
        }*/
  beforeMount() {
    if (typeof this.personInfo === "string") {
      try {
        this.personInfo = JSON.parse(this.personInfo);
      } catch (e) {}
    }
    this.initQueryObj();
  }
}
